import React from "react"

// Libraries
import styled from "styled-components"

// Components
import DownloadsSection from "components/downloads-sections"
import Container from "components/container"

// Downloads
import ImplementationChecklist from "assets/downloads/visby-university/respiratory-health-test/VM-Respi-Implementation-Checklist.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  text-align: center; 
`

const IntroSection = props => {
	return (
		<DownloadsSection
			id="respi-introduction"
			viewAllowed={props.viewAllowed}
			data-filter="respiratory" data-filter-type="devices"
			style={{padding: "80px 0 40px"}}
		>
			<StyledContainer>
				<div className="block__content">
					<h2>Welcome to the Respiratory Health Test Resource Page</h2>
				</div>
				<div className="block">
					<div className="block__material">
						<p>Visby offers a collection of resources that have been summarized and collated based on the categories you see below.
							Our objective is to assist in navigating through the implementation process; resulting in efficient and easier workflows
							boosting patient, clinician and staff satisfaction. If it’s your first time visiting, please consider reviewing
							our <a href={ImplementationChecklist} target="_blank" rel="noopener noreferrer">implementation checklist</a> to get started.
						</p>
					</div>
				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default IntroSection
